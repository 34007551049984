var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTimelineCard',{attrs:{"timestamp":_vm.item.start_at,"user":_vm.user,"nakamal":_vm.nakamal,"linkNakamal":_vm.linkNakamal},scopedSlots:_vm._u([{key:"card-action",fn:function(){return [_c('BaseTimelineCardMenu',{attrs:{"on-share":_vm.onShare,"on-delete":_vm.onDelete,"user-can-delete":_vm.userCanDelete}})]},proxy:true}])},[_c('v-card-text',{attrs:{"id":"map-wrapper"}},[_c('l-map',{ref:"map",staticStyle:{"z-index":"0"},attrs:{"bounds":_vm.bounds,"options":{
        keyboard: false,
        dragging: false,
        zoomControl: false,
        boxZoom: false,
        doubleClickZoom: false,
        scrollWheelZoom: false,
        tap: false,
        touchZoom: false,
      }}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_c('l-polyline',{attrs:{"lat-lngs":_vm.item.data,"weight":5}}),(_vm.nakamal)?_c('l-marker',{attrs:{"icon":_vm.icon,"lat-lng":_vm.nakamal.latLng}},[_c('l-tooltip',{attrs:{"options":{
          permanent: 'true',
          direction: 'bottom',
        }}},[_vm._v(" "+_vm._s(_vm.nakamal.name)+" ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }